<template>
  <div>
    <v-btn @click="dialog = true" small color="" text
      ><v-icon class="mr-1">mdi-list-box</v-icon>Batch Items</v-btn
    >
    <v-dialog v-model="dialog" persistent width="350">
      <v-card>
        <v-card-title>Print Batch Item Status</v-card-title>
        <v-card-text>
          <p>Select a batch to print</p>
          <v-select
            :items="import_batches"
            item-text="batch_description"
            item-value="id"
            v-model="import_batch_id"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      import_batch_id: null,
    };
  },
  computed: {
    ...mapGetters({
      import_batches: "importBatch/import_batches",
      default_server_url: "auth/default_server_url",
    }),
  },
  created() {
    this.import_batches.sort();
  },
  methods: {
    submit() {
      let url =
        this.default_server_url + "/print_batch_import/" + this.import_batch_id;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped></style>
