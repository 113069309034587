<template>
  <div>
    <v-btn @click="dialog = true" icon color="warning"
      ><v-icon>mdi-pencil</v-icon></v-btn
    >
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title>Edit Item Type</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="item.item_type"
            prepend-icon=""
            label="Item Type"
          ></v-text-field>
          <v-text-field
            v-model="item.description"
            prepend-icon=""
            label="Description"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submit" :loading="loading"
            >Submit</v-btn
          >
          <v-btn @click="dialog = false"> Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">addAccountModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },

  methods: {
    ...mapActions({
      edit_item_type: "item_type/edit_item_type",
    }),
    submit() {
      let x = window.confirm("Are you sure you want to proceed?");
      if (x) {
        this.edit_item_type(this.item).then(() => {
          alert("Successfully Edited");
        });
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
